import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../img/logo.png';
import menuIcon from '../img/menu-icon.png';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'es' : 'en');
  };

  return (
    <header>
      <nav>
        <img src={logo} alt="Logo Free Music For Creators" className="sect-icons" />
        <button className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <img src={menuIcon} alt="Menú" className="menu-icon" />
        </button>
        <ul className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
          <li><Link to="/">{t('inicio')}</Link></li>
          <li><Link to="/music">{t('musica')}</Link></li>
          <li><Link to="/how-to-use">{t('comoUsar')}</Link></li>
          <li>
            <button onClick={changeLanguage} id="lang-switch">
              {i18n.language === 'en' ? 'ES' : 'EN'}
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;