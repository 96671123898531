import React, { useEffect, useRef } from 'react';

const AdSense = ({ adSlot, adStyle, adLayout, adFormat }) => {
  const adRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8186364278386462';
    script.async = true;
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);

    script.onload = () => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error('AdSense error:', e);
      }
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <ins
      ref={adRef}
      className="adsbygoogle"
      style={adStyle}
      data-ad-layout={adLayout}
      data-ad-format={adFormat}
      data-ad-client="ca-pub-8186364278386462"
      data-ad-slot={adSlot}
    />
  );
};

export default AdSense;