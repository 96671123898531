// components/Filter.js
import React, { useState, useEffect } from 'react';
import tracksData from '../pistas.json';

function Filter({ onFilterChange, onClose }) {
  const [moods, setMoods] = useState([]);
  const [genres, setGenres] = useState([]);
  const [selectedMoods, setSelectedMoods] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [duration, setDuration] = useState('');
  const [youtubeIntro, setYoutubeIntro] = useState(false);

  useEffect(() => {
    const uniqueMoods = [...new Set(tracksData.tracks.flatMap(track => track.moods))];
    const uniqueGenres = [...new Set(tracksData.tracks.flatMap(track => track.genres))];
    setMoods(uniqueMoods);
    setGenres(uniqueGenres);
  }, []);

  const handleFilterChange = () => {
    onFilterChange({ 
      moods: selectedMoods, 
      genres: selectedGenres, 
      duration, 
      youtubeIntro 
    });
    onClose();
  };

  const handleMoodChange = (mood) => {
    setSelectedMoods(prev => 
      prev.includes(mood) ? prev.filter(m => m !== mood) : [...prev, mood]
    );
  };

  const handleGenreChange = (genre) => {
    setSelectedGenres(prev => 
      prev.includes(genre) ? prev.filter(g => g !== genre) : [...prev, genre]
    );
  };

  return (
    <div className="filter-popup">
      <div className="filter-content">
        <h3>Filtros</h3>
        <div className="filter-row">
          <div className="filter-group">
            <label>Moods</label>
            <div className="checkbox-group">
              {moods.map(mood => (
                <label key={mood} className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={selectedMoods.includes(mood)}
                    onChange={() => handleMoodChange(mood)}
                  />
                  {mood}
                </label>
              ))}
            </div>
          </div>
          <div className="filter-group">
            <label>Géneros</label>
            <div className="checkbox-group">
              {genres.map(genre => (
                <label key={genre} className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={selectedGenres.includes(genre)}
                    onChange={() => handleGenreChange(genre)}
                  />
                  {genre}
                </label>
              ))}
            </div> 
          </div>
          <div className="filter-group">
            <label>Duración</label>
            <select value={duration} onChange={(e) => setDuration(e.target.value)}>
              <option value="">Seleccionar</option>
              <option value="short">Corta (&lt; 1 min)</option>
              <option value="medium">Media (1-3 min)</option>
              <option value="long">Larga (&gt; 3 min)</option>
            </select>
          </div>
          <div className="filter-group">
            <label className="checkbox-label">
              <input 
                type="checkbox" 
                checked={youtubeIntro} 
                onChange={(e) => setYoutubeIntro(e.target.checked)} 
              />
              YouTube Intro
            </label>
          </div>
        </div>
        <div className="filter-actions">
          <button onClick={handleFilterChange}>Aplicar Filtros</button>
          <button onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
}

export default Filter;