import React from 'react';
import { useTranslation } from 'react-i18next';

function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy">
      <h1>{t('privacyPolicy.title')}</h1>
      <section>
        <h2>{t('privacyPolicy.noCookies.title')}</h2>
        <p>{t('privacyPolicy.noCookies.description')}</p>
      </section>
      <section>
        <h2>{t('privacyPolicy.noUserData.title')}</h2>
        <p>{t('privacyPolicy.noUserData.description')}</p>
      </section>
      <section>
        <h2>{t('privacyPolicy.thirdParty.title')}</h2>
        <p>{t('privacyPolicy.thirdParty.description')}</p>
      </section>
      <section>
        <h2>{t('privacyPolicy.contact.title')}</h2>
        <p>{t('privacyPolicy.contact.description')}</p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;