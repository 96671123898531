import React from 'react';
import { useTranslation } from 'react-i18next';

function Sort({ onSortChange }) {
  const { t } = useTranslation();

  const handleSortChange = (event) => {
    onSortChange(event.target.value);
  };

  return (
    <div className="sort-container">
      <label htmlFor="sort-select">{t('ordenarPor')} </label>
      <select id="sort-select" onChange={handleSortChange}>
        <option value="">{t('seleccionaOpcion')}</option>
        <option value="title-asc">{t('tituloAZ')}</option>
        <option value="title-desc">{t('tituloZA')}</option>
        <option value="duration-asc">{t('duracionCorta')}</option>
        <option value="duration-desc">{t('duracionLarga')}</option>
      </select>
    </div>
  );
}

export default Sort;