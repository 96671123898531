import React from 'react';
import { useTranslation } from 'react-i18next';

function HowToUse() {
  const { t } = useTranslation();

  return (
    <div className="how-to-use">
      <section id="como-usar" className="section">
        <h1>{t('comoUsarNuestrasPistas')}</h1>
        <div className="terms-container">
          <ol>
            <li>
              <h3>{t('usoPermitido')}</h3>
              <ul>
                {t('usosPermitidos', { returnObjects: true }).map((uso, index) => (
                  <li key={index}>{uso}</li>
                ))}
              </ul>
            </li>
            <li>
              <h3>{t('modificaciones')}</h3>
              <ul>
                {t('modificacionesPermitidas', { returnObjects: true }).map((mod, index) => (
                  <li key={index}>{mod}</li>
                ))}
              </ul>
            </li>
            <li>
              <h3>{t('restricciones')}</h3>
              <ul>
                {t('listaRestricciones', { returnObjects: true }).map((restriccion, index) => (
                  <li key={index}>{restriccion}</li>
                ))}
              </ul>
            </li>
            <li>
              <h3>{t('derechosDeAutor')}</h3>
              <ul>
                {t('derechosDeAutorInfo', { returnObjects: true }).map((info, index) => (
                  <li key={index}>{info}</li>
                ))}
              </ul>
            </li>
            <li>
              <h3>{t('atribucion')}</h3>
              <p>{t('atribucionInfo')}</p>
            </li>
            <li>
              <h3>{t('pagosYDonaciones')}</h3>
              <ul>
                {t('pagosInfo', { returnObjects: true }).map((info, index) => (
                  <li key={index}>{info}</li>
                ))}
              </ul>
            </li>
            <li>
              <h3>{t('limitacionDeResponsabilidad')}</h3>
              <ul>
                {t('responsabilidadInfo', { returnObjects: true }).map((info, index) => (
                  <li key={index}>{info}</li>
                ))}
              </ul>
            </li>
            <li>
              <h3>{t('cambiosEnLosTerminos')}</h3>
              <p>{t('cambiosInfo')}</p>
            </li>
            <li>
              <h3>{t('terminacion')}</h3>
              <p>{t('terminacionInfo')}</p>
            </li>
            <li>
              <h3>{t('contacto')}</h3>
              <p>{t('contactoInfo')}</p>
            </li>
          </ol>
        </div>
      </section>
    </div>
  );
}

export default HowToUse;