import React from 'react';
import { useTranslation } from 'react-i18next';

function Terms() {
  const { t } = useTranslation();

  return (
    <div className="terms">
      <h1>{t('terms.title')}</h1>
      <section>
        <h2>{t('terms.acceptance.title')}</h2>
        <p>{t('terms.acceptance.content')}</p>
      </section>
      <section>
        <h2>{t('terms.licenseGrant.title')}</h2>
        <p>{t('terms.licenseGrant.content')}</p>
      </section>
      <section>
        <h2>{t('terms.restrictions.title')}</h2>
        <p>{t('terms.restrictions.content')}</p>
      </section>
      <section>
        <h2>{t('terms.ownership.title')}</h2>
        <p>{t('terms.ownership.content')}</p>
      </section>
      <section>
        <h2>{t('terms.userResponsibilities.title')}</h2>
        <p>{t('terms.userResponsibilities.content')}</p>
      </section>
      <section>
        <h2>{t('terms.payments.title')}</h2>
        <p>{t('terms.payments.content')}</p>
      </section>
      <section>
        <h2>{t('terms.termination.title')}</h2>
        <p>{t('terms.termination.content')}</p>
      </section>
      <section>
        <h2>{t('terms.disclaimers.title')}</h2>
        <p>{t('terms.disclaimers.content')}</p>
      </section>
      <section>
        <h2>{t('terms.governingLaw.title')}</h2>
        <p>{t('terms.governingLaw.content')}</p>
      </section>
      <section>
        <h2>{t('terms.changes.title')}</h2>
        <p>{t('terms.changes.content')}</p>
      </section>
      <section>
        <h2>{t('terms.contact.title')}</h2>
        <p>{t('terms.contact.content')}</p>
      </section>
    </div>
  );
}

export default Terms;