import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="footer-links">
        <span>{t('contacto')}: contact@freemusicforcreators.com</span>
        <Link to="/terms">{t('terminos')}</Link>
        <Link to="/privacy-policy">{t('politicaPrivacidad')}</Link>
      </div>
      <p>{t('derechosReservados', { year: new Date().getFullYear() })}</p>
    </footer>
  );
}

export default Footer;