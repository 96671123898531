import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import tracksData from '../pistas.json';
import Filter from '../components/Filter';
import Sort from '../components/Sort';
import AdSense from '../components/AdSense';

function Music() {
  const { t } = useTranslation();
  const [tracks, setTracks] = useState([]);
  const [filteredTracks, setFilteredTracks] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [tracksPerPage, setTracksPerPage] = useState(10);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const audioRef = useRef(new Audio());
  const [playbackError, setPlaybackError] = useState(false);

  useEffect(() => {
    setTracks(tracksData.tracks);
    setFilteredTracks(tracksData.tracks);
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    
    const handleAudioError = (e) => {
      console.error('Error de audio:', e);
      setPlaybackError(true);
    };
    
    const handleAudioEnded = () => {
      setCurrentlyPlaying(null);
    };
    
    audio.addEventListener('error', handleAudioError);
    audio.addEventListener('ended', handleAudioEnded);

    return () => {
      audio.removeEventListener('error', handleAudioError);
      audio.removeEventListener('ended', handleAudioEnded);
    };
  }, []);

  useEffect(() => {
    if (playbackError) {
      alert(t('errorReproduccion'));
      setPlaybackError(false);
      stopPlayback();
    }
  }, [playbackError, t]);

  const stopPlayback = () => {
    const audio = audioRef.current;
    audio.pause();
    audio.currentTime = 0;
    setCurrentlyPlaying(null);
  };

  const handlePlayback = (track) => {
    const audio = audioRef.current;
    
    if (currentlyPlaying === track.id) {
      stopPlayback();
    } else {
      if (currentlyPlaying) {
        stopPlayback();
      }
      
      let fileUrl;
      if (track.premium) {
        fileUrl = `/get-premium-track.php?id=${track.id}`;
      } else {
        const fileName = `${track.id}_${track.title.replace(/\s+/g, '')}.mp3`;
        fileUrl = `${process.env.PUBLIC_URL}/music/${fileName}`;
      }
      
      audio.src = fileUrl;
      audio.currentTime = 0;

      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise.then(_ => {
          setCurrentlyPlaying(track.id);
          if (!track.premium) {
            // Pistas no premium, detener después de 25 segundos
            setTimeout(() => {
              stopPlayback();
            }, 25000);
          }
          // Pistas premium se reproducirán completamente
        }).catch(error => {
          console.error('Error al reproducir la pista:', error);
          setPlaybackError(true);
        });
      }
    }
  };

  const handleFilterChange = (filters) => {
    let result = tracks;

    if (filters.moods.length > 0) {
      result = result.filter(track => 
        filters.moods.every(mood => track.moods.includes(mood))
      );
    }

    if (filters.genres.length > 0) {
      result = result.filter(track => 
        filters.genres.some(genre => track.genres.includes(genre))
      );
    }

    if (filters.duration) {
      result = result.filter(track => {
        const duration = track.duration.split(':').reduce((acc, time) => (60 * acc) + +time);
        switch (filters.duration) {
          case 'short':
            return duration < 60;
          case 'medium':
            return duration >= 60 && duration <= 180;
          case 'long':
            return duration > 180;
          default:
            return true;
        }
      });
    }

    if (filters.youtubeIntro) {
      result = result.filter(track => track.youtubeIntro);
    }

    setNoResults(result.length === 0);
    setFilteredTracks(result);
    setCurrentPage(1);
  };

  const handleSortChange = (sortOption) => {
    let sortedTracks = [...filteredTracks];
    switch (sortOption) {
      case 'title-asc':
        sortedTracks.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 'title-desc':
        sortedTracks.sort((a, b) => b.title.localeCompare(a.title));
        break;
      case 'duration-asc':
        sortedTracks.sort((a, b) => {
          const durationA = a.duration.split(':').reduce((acc, time) => (60 * acc) + +time);
          const durationB = b.duration.split(':').reduce((acc, time) => (60 * acc) + +time);
          return durationA - durationB;
        });
        break;
      case 'duration-desc':
        sortedTracks.sort((a, b) => {
          const durationA = a.duration.split(':').reduce((acc, time) => (60 * acc) + +time);
          const durationB = b.duration.split(':').reduce((acc, time) => (60 * acc) + +time);
          return durationB - durationA;
        });
        break;
      default:
        break;
    }
    setFilteredTracks(sortedTracks);
  };

  const handleDownload = (track, format) => {
    if (format === 'mp3' && !track.premium) {
      const fileNameForUrl = `${track.id}_${track.title.replace(/\s+/g, '')}.mp3`;
      const fileNameForDownload = `${track.title.trim()}.mp3`;
      const fileUrl = `${process.env.PUBLIC_URL}/music/${fileNameForUrl}`;
      
      fetch(fileUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileNameForDownload;
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch(error => {
          alert('Hubo un problema al descargar el archivo. Por favor, inténtelo de nuevo.');
        });
    } else if (track.kofiLink && format === 'mp3') {
      window.open(track.kofiLink, '_blank');
    } else if (track.wavKofiLink) {
      window.open(track.wavKofiLink, '_blank');
    }
  };

  const handleTracksPerPageChange = (event) => {
    setTracksPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const indexOfLastTrack = currentPage * tracksPerPage;
  const indexOfFirstTrack = indexOfLastTrack - tracksPerPage;
  const currentTracks = filteredTracks.slice(indexOfFirstTrack, indexOfLastTrack);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderTracks = () => {
    return currentTracks.map((track, index) => (
      <React.Fragment key={track.id}>
        <div className="track-item">
          <div>{track.title}</div>
          <div>{track.duration}</div>
          <div>{track.genres.join(', ')}</div>
          <div>{track.moods.join(', ')}</div>
          <div className="track-buttons">
            {track.premium && <span className="premium-label">PREMIUM</span>}
            <div className="button-group">
              <button 
                className="play-btn"
                onClick={() => handlePlayback(track)}
              >
                <span className="material-symbols-outlined">
                  {currentlyPlaying === track.id ? 'stop_circle' : 'play_circle'}
                </span>
              </button>
              <button 
                className={`download-btn ${track.premium ? 'premium' : ''}`}
                onClick={() => handleDownload(track, 'mp3')}
                disabled={track.premium && !track.kofiLink}
              >
                MP3 
              </button>
              <button 
                className={`download-btn ${track.premium ? 'premium' : ''}`}
                onClick={() => handleDownload(track, 'wav')}
                disabled={!track.wavKofiLink}
              >
                WAV
              </button>
            </div>
          </div>
        </div>
        {tracksPerPage === 20 && index === 9 && (
           <div className="ad-space">
           <AdSense
             adSlot="6876206679"
             adStyle={{display: 'block', textAlign: 'center'}}
             adLayout="in-article"
             adFormat="fluid"
           />
          </div>
        )}
      </React.Fragment>
    ));
  };

  const pageNumbers = () => {
    const totalPages = Math.ceil(filteredTracks.length / tracksPerPage);
    if (totalPages <= 3) {
      return [...Array(totalPages)].map((_, i) => i + 1);
    }
    if (currentPage <= 2) return [1, 2, 3];
    if (currentPage >= totalPages - 1) return [totalPages - 2, totalPages - 1, totalPages];
    return [currentPage - 1, currentPage, currentPage + 1];
  };

  return (
    <div className="music">
       <AdSense
        adSlot="6876206679"
        adStyle={{display: 'block', textAlign: 'center'}}
        adLayout="in-article"
        adFormat="fluid"
      />

      <section id="music-content">
        <div className="music-controls">
          <button onClick={() => setShowFilters(!showFilters)} className="filter-button">
            {t('filtrar')}
          </button>
          <Sort onSortChange={handleSortChange} />
          <div className="tracks-per-page">
            <label htmlFor="tracksPerPage">{t('temasPorPagina')}</label>
            <select id="tracksPerPage" value={tracksPerPage} onChange={handleTracksPerPageChange}>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
          </div>
        </div>
        {showFilters && <Filter onFilterChange={handleFilterChange} onClose={() => setShowFilters(false)} />}
        <div id="music-list">
          <div className="track-header">
            <div>{t('titulo')}</div>
            <div>{t('duracion')}</div>
            <div>{t('generos')}</div>
            <div>{t('moods')}</div>
            <div>{t('acciones')}</div>
          </div>
          {noResults ? (
            <div className="no-results">{t('noResultados')}</div>
          ) : (
            renderTracks()
          )}
        </div>
        <div className="pagination">
          <button 
            onClick={() => paginate(currentPage - 1)} 
            disabled={currentPage === 1}
          >
            {t('anterior')}
          </button>
          {pageNumbers().map(number => (
            <button
              key={number}
              onClick={() => paginate(number)}
              className={currentPage === number ? 'active' : ''}
            >
              {number}
            </button>
          ))}
          <button 
            onClick={() => paginate(currentPage + 1)} 
            disabled={currentPage === Math.ceil(filteredTracks.length / tracksPerPage)}
          >
            {t('siguiente')}
          </button>
        </div>
      </section>
    </div>
  );
}

export default Music;