import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import libraryMusicIcon from '../img/library_music-icon.png';
import downloadIcon from '../img/download-icon.png';
import artistIcon from '../img/artist-icon.png';

function Home() {
  const { t } = useTranslation();

  return (
    <div className="home">
      <section id="hero">
        <h1>{t('freeMusicForCreators')}</h1>
        <p>{t('musicaGratisParaCreadores')}</p>
      </section>

      <section id="features">
        <div className="feature">
          <div className="icon-circle">
            <img src={libraryMusicIcon} alt={t('pistasDisponibles')} className="sect-icons" />
          </div>
          <h2>{t('pistasDisponibles')}</h2>
          <p>{t('variedadGeneros')}</p>
        </div>
        <div className="feature">
          <div className="icon-circle">
            <img src={downloadIcon} alt={t('descargaIlimitada')} className="sect-icons" />
          </div>
          <h2>{t('descargaIlimitada')}</h2>
          <p>{t('sinRestricciones')}</p>
        </div>
        <div className="feature">
          <div className="icon-circle">
            <img src={artistIcon} alt={t('libreAtribucion')} className="sect-icons artist-icon" />
          </div>
          <h2>{t('libreAtribucion')}</h2>
          <p>{t('utilizarSinMencionar')}</p>
        </div>
      </section>

      <section id="cta">
        <Link to="/music" className="btn-primary">{t('explorarMusica')}</Link>
      </section>

      <section id="pricing">
        <h2>{t('preciosYFormatos')}</h2>
        <table className="price-table">
          <thead>
            <tr>
              <th>{t('tipo')}</th>
              <th>MP3</th>
              <th>WAV</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('noPremium')}</td>
              <td>{t('gratis')}</td>
              <td>1.5€</td>
            </tr>
            <tr>
              <td>{t('premium')}</td>
              <td>1.5€</td>
              <td>2.5€</td>
            </tr>
          </tbody>
        </table>
        <div className="pricing-explanation">
          <ul>
            <li><strong>WAV:</strong> {t('precioFormatos.explicacionWAV')}</li>
            <li><strong>MP3:</strong> {t('precioFormatos.explicacionMP3')}</li>
          </ul>
        </div>
      </section>

      <section id="payments">
        <h2>{t('pagos')}</h2>
        <p>{t('explicacionPagos')}</p>
      </section>
    </div>
  );
}

export default Home;